import { IpcRendererEvent } from 'electron'
import { TrackedDirectory } from '@/components/librarySection/library/Library.tsx'
import { getSessionToken, isSessionTokenExpired } from '@descope/react-sdk'

export type OpenPDFOptions = {
  url: string
}
export type DownloadCadOptions = {
  url: string
}
export type resizeAppWindowOptions = {
  isOpen: boolean
}

export type receivedDataOptions = {
  [key: string]: any
}

type PathAndLocation = {
  filepath?: string
  location?: {
    loc: {
      x: 'number'
      y: 'number'
      z: 'number'
    }
    orientation: number[][]
  }
}

export interface LocalFileTracking {
  trackedDirectories: TrackedDirectory[]
}

export type IpcCallbackWithData<T = any> = (event: IpcRendererEvent, data: T) => void

const app = () => {
  if (!(window as any).app) {
    console.error('App not initialized')
    return
  }

  return (window as any).app
}

export async function openPDF(opts: OpenPDFOptions) {
  await app().openPDF(opts)
}

export async function resizeAppWindow(opts: resizeAppWindowOptions) {
  await app().resizeAppWindow(opts)
}

export function onReceivedData(callback: IpcCallbackWithData) {
  if (!app()) {
    return
  }

  app().onReceivedData(callback)
}

export function onReceivedDataV2(callback: IpcCallbackWithData) {
  app().onReceivedDataV2(callback)
}

export function onInputSubmitted(callback: IpcCallbackWithData | null) {
  if (!app()) {
    return
  }

  app().onInputSubmitted(callback)
}
export function openInputWindow(inputType: string | undefined) {
  app().openInputWindow(inputType)
}

export function submitInput(inputText: string | undefined, inputType: string | undefined) {
  app().submitInput(inputText, inputType)
}

export function hideInput() {
  app().hideInput()
}

export function hideMinimizeButton(hide: boolean) {
  app().hideMinimizeButton(hide)
}

export function minimizeApp() {
  app().minimizeApp()
}

export function addListener<T = any>(eventName: string, callback: (data: T) => any) {
  if (!app()) {
    return
  }

  app().on(eventName, callback)
}

export function onRemoveListener(eventName: string, callback: IpcCallbackWithData) {
  if (!app()) {
    return
  }

  app().removeListener(eventName, callback)
}

export async function downloadCad(opts: DownloadCadOptions) {
  await app().downloadCad(opts)
}

export async function sendDownloadCadToSW() {
  await app().sendDownloadCadToSW()
}

export async function openCadInSW(opt?: PathAndLocation) {
  await app().openCadInSW(opt)
}

export async function getFileSyncStatus(): Promise<LocalFileTracking> {
  return await app().getFileSyncStatus()
}

export async function addDirectoryToSync(): Promise<LocalFileTracking> {
  return await app().addDirectoryToSync()
}

export function removeDirectoryFromSync(filePath: string) {
  app().removeDirectoryFromSync(filePath)
}

export async function onRequestHeaders(userId: string) {
  if (!app()) {
    return
  }

  try {
    const sessionToken = getSessionToken()
    const isExpired = isSessionTokenExpired(sessionToken)

    app().sendHeadersResponse({
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionToken}`,
      },
      tenantId: userId,
      isExpired,
    })
  } catch (error) {
    console.error('Error sending headers response:', error)
  }
}

export function sendLogout() {
  app().sendLogout()
}
