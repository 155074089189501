import { useEffect } from 'react';
import { useOnlineStore } from "@/store/useIsOnlineStore.ts";

export const useOnlineStatus = () => {
  const { isOnline, setIsOnline, isFirstRender, setIsFirstRender } = useOnlineStore()

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => {
      setIsOnline(false)
      setIsFirstRender(false)
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [setIsFirstRender, setIsOnline]);

  return { isOnline, isFirstRender };
};

