export enum ContentType {
  Text = 'text',
  Attachments = 'attachments',
  Component = 'component',
  Code = 'code',
  Progress = 'progress',
  End = 'end',
  Description = 'description',
  ToolsRequest = 'toolsRequest',
  ToolResponse = 'toolResponse',
}
export type Content = {
  type: ContentType
  content: any
}

export enum ChatSender {
  User = 'user',
  Assistant = 'assistant',
  Tool = 'tool',
}

export interface ChatMessage {
  sender: ChatSender
  text: string
}

export type MessageContent = {
  type: ContentType
  [name: string]: any
}

export function safeJsonParse<T>(str: string): T | undefined {
  try {
    return JSON.parse(str) as T
  } catch {
    return undefined
  }
}

export function formatContent(type: ContentType, content: any): MessageContent {
  return { type, [type]: content }
}

export function extractRawContent(messageContent: MessageContent): Content {
  return {
    type: messageContent.type,
    content: messageContent[messageContent.type],
  }
}

export function formatContentMessage(sender: ChatSender, type: ContentType, content: any): ChatMessage {
  return {
    sender,
    text: JSON.stringify([formatContent(type, content)]),
  }
}

export function formatMultipleContentsMessage(sender: ChatSender, contents: Content[]): ChatMessage {
  return {
    sender,
    text: JSON.stringify(contents.map(({ type, content }) => formatContent(type, content))),
  }
}

export function containsContentType(message: ChatMessage, type: ContentType) {
  const parsedMessage = safeJsonParse<Array<Content>>(message.text)
  if (!parsedMessage || !Array.isArray(parsedMessage)) {
    return false
  }
  return parsedMessage.some(message => message.type === type)
}

export function findContent(messageContents: MessageContent[], type: ContentType) {
  const messageContent = messageContents.find(content => content.type === type) as MessageContent
  return messageContent ? messageContent[type] : undefined
}

export function getMessageContent(message: ChatMessage | MessageContent, type: ContentType) {
  const parsedMessage = safeJsonParse<Array<Content>>(message.text)
  if (!parsedMessage || !Array.isArray(parsedMessage)) {
    return undefined
  }
  return findContent(parsedMessage, type)
}

export function getMessageContents(message: ChatMessage) {
  const parsedMessages = safeJsonParse<MessageContent[]>(message.text)
  if (!parsedMessages) return []
  return Array.isArray(parsedMessages) ? parsedMessages : [parsedMessages]
}

export function getInnerMessageContents(messageContent: MessageContent) {
  const rawContent = extractRawContent(messageContent)
  const parsedMessages = safeJsonParse<MessageContent[]>(rawContent.content)
  if (!parsedMessages) return []
  return Array.isArray(parsedMessages) ? parsedMessages : [parsedMessages]
}
