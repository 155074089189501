import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom'

import * as analytics from '@/lib/analytics.ts'
import { AuthProvider } from '@descope/react-sdk'
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components'
import { darkTheme } from '@/styles/theme.ts'
import { ThemeProvider } from '@mui/material/styles'
import { ThemeProvider as StyledThemeProvider } from '@emotion/react'
import { LeoThemeProvider } from '@/src-ideation/hooks/useThemeContext'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createDefaultFeatureFlags, FeatureFlagsContext } from '@/lib/featureFlags.ts'
import { router } from '@/router/Router.tsx'

const queryClient = new QueryClient()

export const App = () => {
  useEffect(() => {
    analytics.track('Component Mounted', {
      component: 'App',
    })

  }, [])

  return (
    <React.StrictMode>
      <AuthProvider
        projectId="P2S4sM8KKC0gdOu0eBgmT4v0gfvf"
        baseUrl="https://auth.app.getleo.ai"
      >
        <StyledComponentsThemeProvider theme={darkTheme}>
          <ThemeProvider theme={darkTheme}>
            <StyledThemeProvider theme={darkTheme}>
              <LeoThemeProvider theme={darkTheme}>
                <QueryClientProvider client={queryClient}>
                  <FeatureFlagsContext.Provider value={createDefaultFeatureFlags()}>
                    <RouterProvider router={router} />
                  </FeatureFlagsContext.Provider>
                </QueryClientProvider>
              </LeoThemeProvider>
            </StyledThemeProvider>
          </ThemeProvider>
        </StyledComponentsThemeProvider>
      </AuthProvider>
    </React.StrictMode>
  )
}
