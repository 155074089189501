import { create } from 'zustand'

export type FirstSeenElements = {
  [key: string]: boolean
}
type OnboardingType = 'ideation' | 'chat' | 'electron'

interface AppState {
  openEraserCanvas: boolean
  setOpenEraserCanvas: (openEraserCanvas: boolean) => void

  isFirstSeenApp: boolean
  setIsFirstSeenApp: (isFirstSeenApp: boolean) => void
  firstSeenElements: FirstSeenElements
  setFirstSeenElements: (firstSeenElements: FirstSeenElements) => void

  isOnboardingOpens: boolean
  setIsOnboardingOpens: (isOnboardingOpens: boolean) => void

  onboardingStep: number
  setOnboardingStep: (onboardingStep: number) => void

  onboardingType: OnboardingType
  setOnboardingType: (onboardingType: OnboardingType) => void

  isResourceClicked: boolean
  setIsResourceClicked: (isResourceClicked: boolean) => void
}

export const useAppState = create<AppState>(set => ({
  openEraserCanvas: false,
  setOpenEraserCanvas: openEraserCanvas => set({ openEraserCanvas }),

  isFirstSeenApp: false,
  setIsFirstSeenApp: isFirstSeenApp => set({ isFirstSeenApp }),
  firstSeenElements: {},
  setFirstSeenElements: firstSeenElements => set(store => ({ ...store.firstSeenElements, firstSeenElements })),

  isOnboardingOpens: false,
  setIsOnboardingOpens: isOnboardingOpens => set({ isOnboardingOpens }),

  onboardingStep: 0,
  setOnboardingStep: onboardingStep => set({ onboardingStep }),

  onboardingType: 'chat',
  setOnboardingType: onboardingType => set({ onboardingType }),

  isResourceClicked: false,
  setIsResourceClicked: isResourceClicked => set({ isResourceClicked }),
}))
