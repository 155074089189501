import * as observability from '@/lib/observability.ts'
import * as analytics from '@/lib/analytics.ts'
import * as errors from '@/api/errors.ts'
import { ArgsType } from '@/store/useChat.ts'
import { handleNotFoundError, handleSchemaValidationError, handleUnknownError } from '@/api/error-handlers.ts'
import { BASE_URL } from '@/lib/config.ts'
import { createHeaders } from '@/api/utils.ts'
import { useSessionStore } from '@/store/useSessionStore.ts'
import { createNewSession } from '@/api/sessions.ts'

interface cadDataType {
  [key: string]: any
}

interface CadDataPostRequestBody {
  geometry: cadDataType
  userMessage: string
}

export type CadDataPostRequestAssembly = {
  childrenList: Array<{
    componentId: string // mapped via synced directory information
    locations: Array<{
      loc: {
        // Location coordinates
        x: 'number' // X coordinate
        y: 'number' // Y coordinate
        z: 'number' // Z coordinate
      }
      orientation: number[][] // 1x3 matrix of floats representing the orientation
    }>
  }>
}

export type CadDataPostRequestBodyV2 = {
  userMessage: string
  assemblyInfo: CadDataPostRequestAssembly
  assemblyRoot: string
}

export function useCadData() {
  const getSessionAndRevision = async (numberOfSession: number | undefined) => {
    if (numberOfSession === undefined) {
      const newSession = await createNewSession()
      useSessionStore.getState().setSessionNum(newSession.sessionNum)
      useSessionStore.getState().setRevisionNum(newSession.revisionNum)

      return newSession
    } else {
      return {
        sessionNum: numberOfSession,
      }
    }
  }

  async function postCadData(content: CadDataPostRequestBody): Promise<any> {
    const sessionNum = useSessionStore.getState().sessionNum
    const currentSession = await getSessionAndRevision(sessionNum)

    const url = `${BASE_URL}/api/v1/session/${currentSession.sessionNum}/cad_data`

    const options = {
      method: 'POST' as const,
      headers: await createHeaders(),
      body: JSON.stringify(content),
    }

    try {
      const response = await fetch(url, options)

      if (!response.ok) {
        throw new errors.FetchError(response.statusText, response, {
          url,
          ...options,
          params: { sessionNum: currentSession.sessionNum, revisionNum: 1 },
        })
      }

      return response.json()
    } catch (error: any) {
      handleError({
        error,
        tags: {
          url,
          authorization: options.headers.Authorization,
        },
      })
    }
  }

  async function postCadDataV2(content: CadDataPostRequestBodyV2): Promise<any> {
    const sessionNum = useSessionStore.getState().sessionNum
    const currentSession = await getSessionAndRevision(sessionNum)
    const url = `${BASE_URL}/api/v2/session/${currentSession.sessionNum}/cad_data`
    const options = {
      method: 'POST' as const,
      headers: await createHeaders(),
      body: JSON.stringify(content),
    }

    try {
      const response = await fetch(url, options)

      if (!response.ok) {
        throw new errors.FetchError(response.statusText, response, {
          url,
          ...options,
          params: { sessionNum: currentSession.sessionNum, revisionNum: 1 },
        })
      }

      return await response.json()
    } catch (error) {
      handleError({
        error,
        tags: {
          url,
          authorization: options.headers.Authorization,
        },
      })
    }
  }

  async function handleError({ error, tags }: { error: unknown; tags: any }) {
    observability.captureException(`Failed to post cad data`, {
      error,
      tags,
    })

    analytics.track('Post Cad Data Error', { error })

    if (!(error instanceof Error)) {
      throw new errors.UnknownApiError(`Error with fetching cad data, threw non error object`)
    }

    if (error instanceof errors.FetchError) {
      const {
        response,
        requestConfig: { params, data },
      } = error

      const errorResponse = response.body ? await response.json() : new Error(response.statusText)
      const args: ArgsType = {
        headers: response.headers,
        url: response.url,
        method: 'PUT',
        params,
        data,
      }

      if (errorResponse.isSchemaValidationError) {
        handleSchemaValidationError(errorResponse, args)
      }

      if (response.status === 404) {
        handleNotFoundError(errorResponse, args)
      }

      if (response.status === 400 && errorResponse?.reason === 'Policy Violation') {
        observability.captureException(`Policy Violation Error PUT ${response.url}`, {
          error: errorResponse,
          tags: { url: response.url },
        })

        analytics.track('Policy Violation Error', {
          error: errorResponse,
        })

        throw new errors.PolicyViolationError(errorResponse.message)
      }

      if (response.status === 424 && errorResponse?.reason === 'Policy Violation') {
        observability.captureException(`Azure OpenAi Policy Violation Error PUT ${response.url}`, {
          error: errorResponse,
          tags: { url: response.url },
        })

        analytics.track("Azure OpenAI's content management policy", {
          error: errorResponse,
        })

        throw new errors.PolicyViolationError(errorResponse.message)
      }
    }

    handleUnknownError(error, (error as errors.FetchError).requestConfig ?? {})
  }

  return {
    postCadData,
    postCadDataV2,
  }
}
