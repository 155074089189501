import React, { useEffect } from 'react'
import { Descope, useSession } from '@descope/react-sdk'
import * as analytics from '../../lib/analytics'
import { ROUTE } from '@/router/Router'
import { AuthLayout } from './Layout'
import { useContextualNavigate } from '@/hooks/useContextualNavigate.ts'

const flowId = 'sign-up-v3'

// const styleHack = `
//   div#wc-root > div:first-of-type > div.descope-container:nth-of-type(2) > div:first-of-type > div:first-of-type {
//     display: flex;
//     flex-direction: column;
//     gap: 16px;
//   }
// `;
//
// function appendStyleHack(mutations, observer) {
//   console.log({ mutations, observer });
//   const style = document.createElement("style");
//   style.innerHTML = styleHack;
//   // .current.rootElement.appendChild(style);
// }

export default function SignUp() {
  const { isAuthenticated } = useSession()
  const navigate = useContextualNavigate()
  // const ref = useRef(null);
  // useEffect(() => {
  //   if (ref) {
  //     console.log({ time: new Date().getTime(), ref, current: ref.current });
  //     if (ref.current) {
  //       const mutationObserver = new MutationObserver(appendStyleHack);
  //       const observationInit = { subtree: true, childList: true };
  //       mutationObserver.observe(ref.current, observationInit);
  //     }
  //   }
  // }, [ref]);

  useEffect(() => {
    // TODO: should be uniform in the routing infrastructure
    analytics.track('Component Mounted', { component: 'Sign Up' })
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      navigate(ROUTE.MAIN)
    }
  }, [isAuthenticated, navigate])

  const handleSuccess = event => {
    if (event?.detail?.firstSeen) {
      analytics.track('First Seen', {
        authDetails: event.detail,
        flowId,
        reactComponent: 'Auth/SignUp',
      })
      return navigate(ROUTE.ONBOARDING)
    }
  }

  const handleError = () => {
    analytics.track('Error In Sign Up Component')
  }

  return (
    <AuthLayout>
      <Descope flowId={flowId} onSuccess={handleSuccess} onError={handleError} />
    </AuthLayout>
  )
}
