import { useEffect, useCallback, useRef } from 'react'
import { openInputWindow } from '@/lib/app'
import { CadDataPostRequestAssembly, useCadData } from '@/api/cad-data'
import { useSessionStore } from '@/store/useSessionStore'
import { useChat } from '@/store/useChat'
import * as analytics from '@/lib/analytics'
import { IpcRendererEvent } from 'electron'
import { registerIpcHandlers } from './ipcHandlerManager'
import { popToastErrorMessage } from '@/api/utils.ts'
import { ChatSender } from '@/lib/messages.ts'
import { defaultAndUnrealisticErrorMessage } from '@/constants/msgEnums.ts'

const IntegrationInput = () => {
  const { sessionNum } = useSessionStore(store => store)
  const { setIsLoading, isLoading, messages, setMessages } = useChat(store => store)

  const { postCadData, postCadDataV2 } = useCadData()

  const inputType = useRef('')
  const geometryData = useRef(null)
  const assemblyData = useRef<CadDataPostRequestAssembly | Error | null>(null)

  const handleReceivedData = useCallback((_event: IpcRendererEvent, data: any) => {
    geometryData.current = data
    inputType.current = 'geometryData'
    openInputWindow('geometryData')
  }, [])

  const handleReceivedDataV2 = useCallback((_event: IpcRendererEvent, data: CadDataPostRequestAssembly | Error) => {
    if (data instanceof Error) {
      popToastErrorMessage(data.message)
      return
    }

    assemblyData.current = data
    inputType.current = 'assemblyData'
    openInputWindow('assemblyData')
  }, [])

  const handleInputSubmitted = useCallback(
    async (_event: IpcRendererEvent, text: string) => {
      let content: any
      const isGeometryData = inputType.current === 'geometryData'

      if (isGeometryData) {
        content = { userMessage: text, geometry: geometryData.current }
      } else {
        content = { userMessage: text, ...assemblyData.current }
      }

      const currentMessages = useChat.getState().messages

      setIsLoading(true)
      const stringyMessage = JSON.stringify([{ type: 'text', text }])
      setMessages([...currentMessages, { sender: ChatSender.User, text: stringyMessage }])

      if (isLoading) return

      try {
        const data = isGeometryData ? await postCadData(content) : await postCadDataV2(content)
        setIsLoading(false)

        const stringyAns = JSON.stringify(data.result.messagesList)
        const newMessage = { sender: ChatSender.Assistant, text: stringyAns }

        const result = {
          messages: [...currentMessages, newMessage],
          isValid: true,
        }

        analytics.track('Cad Data Received', { isValid: result.isValid })

        if (result.isValid) {
          setMessages([...result.messages])
        }
      } catch (e) {
        setIsLoading(false)
        popToastErrorMessage(defaultAndUnrealisticErrorMessage)
        setMessages(currentMessages)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [messages, isLoading, inputType]
  )

  useEffect(() => {
    registerIpcHandlers(handleReceivedData, handleInputSubmitted, handleReceivedDataV2)
  }, [handleReceivedData, handleInputSubmitted, sessionNum, handleReceivedDataV2])

  return null
}

export default IntegrationInput
