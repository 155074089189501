import React, { useState } from 'react'
import styled from 'styled-components'
import loadingGif from '../../../assets/icons/Logo_animation_Bright.gif'
import loadingGifStart from '../../../assets/icons/logo-animation-bright-start.png'
import { Button as CommonButton } from '../../button/Button.tsx'
import { useChat } from '@/store/useChat.ts'
import { useChatDownloadedImages } from '@/store/useChatDownloadedImages.ts'
import { useAppState } from '@/store/useAppState.ts'
import { tutorialImages, tutorialMessages } from '@/constants/onboardingCards.tsx'
import { sleep } from '@/src-ideation/utils/time.ts'

export const StyledChatButton = styled(CommonButton)`
  min-width: auto !important;
  width: 2.86rem;
  height: 2.86rem;
  border-radius: var(--leo-radius) !important;
  background: var(--purple-100) !important;

  &[disabled] {
    background-color: var(--dark-50) !important;
    opacity: 0.4;
  }

  img {
    border-radius: var(--leo-radius);
    width: 3.75rem;
    height: 3.75rem;
    object-fit: contain;
  }
`

interface ButtonProps {
  onClick: () => void
  disableByInputField: boolean
}

export const ChatButton: React.FC<ButtonProps> = ({ onClick, disableByInputField }) => {
  const { isLoading, setMessages, setIdeationImages } = useChat(store => store)
  const { uploadImage } = useChatDownloadedImages(store => store)
  const { onboardingStep, setOnboardingStep } = useAppState(store => store)
  const [onboardingLoading, setOnboardingLoading] = useState(false)

  const isDisabled = isLoading || uploadImage || onboardingLoading || !disableByInputField

  const tutorialClick = async () => {
    setOnboardingLoading(true)
    await sleep(1500)
    setOnboardingLoading(false)
    setOnboardingStep(onboardingStep + 1)
    setMessages(tutorialMessages as any)
    setIdeationImages(tutorialImages)
  }

  return (
    <StyledChatButton
      id={'chat-input-button'}
      aria-label="send"
      onClick={onboardingStep > 0 ? tutorialClick : onClick}
      disabled={isDisabled}
      variant="contained"
    >
      {isLoading || onboardingLoading ? (
        <img src={loadingGif} alt="Loading..." />
      ) : (
        <img src={loadingGifStart} alt="ready" />
      )}
    </StyledChatButton>
  )
}
