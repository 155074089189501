export const supportEmail = () => `support@getleo.ai`

export function getUrlOverrides() {
  const overrides = new Map<string, any>()
  const urlOverrides = new URLSearchParams(window.location.search)
  const isDebugMode = ['true', '1'].includes(urlOverrides.get('leo-debug') || '')
  overrides.set('DEBUG_MODE', isDebugMode)
  if (isDebugMode) {
    if (['l', 'local', '3'].includes(urlOverrides.get('leo-env') || '')) {
      overrides.set('BASE_URL', 'http://localhost:8000')
    } else if (['s', 'staging', '2'].includes(urlOverrides.get('leo-env') || '')) {
      overrides.set('BASE_URL', 'https://api.staging.leo-primary.com')
    } else if (['p', 'prod', 'production', '1'].includes(urlOverrides.get('leo-env') || '')) {
      overrides.set('BASE_URL', 'https://api.getleo.ai')
    }
  }
  return overrides
}

export const URL_OVERRIDES = getUrlOverrides()
