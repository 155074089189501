import { create } from 'zustand'

interface ChatDownloadedImagesInterface {
  showImage: boolean
  setShowImage: (showImage: boolean) => void
  uploadImage: boolean
  setUploadImage: (showImage: boolean) => void
  imageSrc: string
  setImageSrc: (imageSrc: string) => void
}

export const useChatDownloadedImages = create<ChatDownloadedImagesInterface>(( set ) => ({
  showImage: false,
  setShowImage: ( showImage ) => set({ showImage }),
  uploadImage: false,
  setUploadImage: ( uploadImage ) => set({ uploadImage }),
  imageSrc: '',
  setImageSrc: ( imageSrc ) => set({ imageSrc }),
}))
