import { Descope, useSession } from '@descope/react-sdk'
import React, { useEffect } from 'react'
import * as analytics from '../../lib/analytics'
import { ROUTE } from '@/router/Router'
import { AuthLayout } from './Layout'
import { hideMinimizeButton } from '@/lib/app.ts'
import { isRunningInElectron } from '@/constants/generalFunctions.ts'
import { useContextualNavigate } from '@/hooks/useContextualNavigate.ts'

const flowId = 'sign-up-or-in-v4'

export default function SignIn() {
  const { isAuthenticated } = useSession()
  const navigate = useContextualNavigate()

  useEffect(() => {
    if (isRunningInElectron()) {
      hideMinimizeButton(true)
    }
    analytics.track('Component Mounted', { component: 'Sign In' })
    return () => {
      if (isRunningInElectron()) {
        hideMinimizeButton(false)
      }
    }
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      navigate(ROUTE.MAIN)
    }
  }, [isAuthenticated, navigate])

  const handleSuccess = event => {
    if (event?.detail?.firstSeen) {
      analytics.track('First Seen', {
        authDetails: event.detail,
        flowId,
        reactComponent: 'Auth/SignIn',
      })
    }
  }

  const handleError = () => {
    analytics.track('Error In Sign In Component')
  }

  return (
    <AuthLayout>
      <Descope flowId={flowId} onSuccess={handleSuccess} onError={handleError} />
    </AuthLayout>
  )
}
