// General
import { extractPrNumber } from '@/api/utils.ts'
import { UpscaleData } from "@/store/useGalleryStore.ts";
import { URL_OVERRIDES } from '@/lib/urls.ts'

const env = import.meta.env

// Use this to test specific functionality deployed to production, but still in beta
export const DEBUG_MODE = URL_OVERRIDES.get('DEBUG_MODE') || env.VITE_DEBUG_MODE || false

// Mixpanel
export const MIXPANEL_TOKEN = env.VITE_MIXPANEL_TOKEN || '297bc56853b7e96b7e476a08018e54e2'

//SENTRY DEV
// export const SENTRY_DSN =
//   "https://831a0eb73e43689a46ac8b790c73a72e@o4505905649090560.ingest.sentry.io/4505905746477056";
// export const SENTRY_ENVIRONMENT = "develop";

export const SENTRY_DSN = env.VITE_SENTRY_DSN
export const SENTRY_ENVIRONMENT = env.VITE_SENTRY_ENVIRONMENT

export const BASE_URL = URL_OVERRIDES.get('BASE_URL') || env.VITE_API_ENDPOINT || 'https://api.getleo.ai'

export const WEBSITE_URL = 'https://www.getleo.ai/'

export const PRIVACY_POLICY_URL = 'https://www.getleo.ai/privacy'

export const SLACK_URL = 'https://join.slack.com/t/leoaipublic/shared_invite/zt-25ktda6yb-xJA~q5wf1hP3bAfNCFus9Q'

export const SUPPORT_URL = 'support@getleo.ai'

export const BUY_LEO = env.BUY_LEO || 'https://buy.stripe.com/8wMg1m8Fj7zS5LqbIM'

export const CONTACT_SALES = env.CONTACT_SALES || 'https://www.getleo.ai/contact-sales'

//todo this is a temp fix until we solve the issue with the env variables
const prNumber = extractPrNumber(window.location.href)
export const DOWNLOAD_APP_LINK = prNumber
  ? `https://storage.googleapis.com/installer-version-distribution-public/windows/preview/pr-${prNumber}/Leo-preview.exe`
  : window.location.href.includes('beta')
    ? 'https://storage.googleapis.com/installer-version-distribution-public/windows/latest/Leo-beta.exe'
    : env.DOWNLOAD_APP_LINK ||
      'https://storage.googleapis.com/installer-version-distribution-public/windows/latest/Leo.exe'

export const FILE_SYNC_ENABLED = env.FILE_SYNC_ENABLED || false
export const WEB_SOCKET_TIMEOUT = 120000

export const FEATURE_MAGIC_ERASER = env.REACT_APP_FEATURE_MAGIC_ERASER === 'true' || true
export const FEATURE_SHARE_IMAGE = env.REACT_APP_FEATURE_SHARE_IMAGE === 'true' || true
export const FEATURE_NO_GENERATE = env.REACT_APP_FEATURE_NO_GENERATE === 'true' || false


// General

export const DEVELOPMENT = env.REACT_APP_DEVELOPMENT === 'true' || false

// Mixpanel

// Sentry DSN
// https://831a0eb73e43689a46ac8b790c73a72e@o4505905649090560.ingest.sentry.io/4505905746477056

// Don't export this so we know for a fact that no one uses it.
// const BASE_URL = env.REACT_APP_API_BASE_URL || env.VITE_API_ENDPOINT || 'http://0.0.0.0:8000'
// 'https://api.prod.leo-primary.com'

export const POLLING_TIMEOUT = 600000
export const POLLING_INTERVAL = 5000

// Export each endpoint url separately - Please keep it that way.
// Reasons being that we sometimes want to change host for some endpoints,
// Either in the future or in the present when developing.
// TODO: Please, let's move to react-query for example.
export const LEO_SPEC_ENDPOINT = `${BASE_URL}/api/v2/spec`
export const LEO_SPEC_EXPORT_ENDPOINT = `${BASE_URL}/api/v2/spec/export`
export const KYC_ENDPOINT = `${BASE_URL}/api/v1/user/kyc`
export const SESSION_EXPORT_ENDPOINT = `${BASE_URL}/api/v1/session/export`
export const IMAGE_SHARE_ENDPOINT = `${BASE_URL}/api/v3/session/:sessionNum/revision/:revisionNum/image/:imageNum/share`

// V3
export const IMAGE_LIST_ENDPOINT = `${BASE_URL}/api/v3/image`
export const CREATE_SESSION_ENDPOINT = `${BASE_URL}/api/v3/session`
export const LIST_SESSION_ENDPOINT = `${BASE_URL}/api/v3/session`
export const CREATE_REVISION_ENDPOINT = `${BASE_URL}/api/v3/session/:sessionNum/revision`
export const LEO_CHAT_ENDPOINT = `${BASE_URL}/api/v4/session/:sessionNum/revision/:revisionNum/chat`
export const TEXT_TO_IMAGE_ENDPOINT = `${BASE_URL}/api/v3/session/:sessionNum/revision/:revisionNum/image/text-to-image`
export const SKETCH_TO_IMAGE_ENDPOINT = `${BASE_URL}/api/v3/session/:sessionNum/revision/:revisionNum/image/sketch-to-image`
export const UPLOAD_TO_IMAGE_ENDPOINT = `${BASE_URL}/api/v3/session/:sessionNum/revision/:revisionNum/image/upload-to-image`
export const VARIATION_IMAGE_ENDPOINT = `${BASE_URL}/api/v3/session/:sessionNum/revision/:revisionNum/image/variation`
export const UPSCALE_IMAGE_ENDPOINT = `${BASE_URL}/api/v3/session/:sessionNum/revision/:revisionNum/image/upscale`
export const IMAGE_TO_3D_ENDPOINT = `${BASE_URL}/api/v3/session/:sessionNum/revision/:revisionNum/image/imageTo3D`
export const IMAGE_ERASE_ENDPOINT = `${BASE_URL}/api/v3/session/:sessionNum/revision/:revisionNum/image/vary-region`
export const PROJECT_DOCS = `${BASE_URL}/api/v3/session/:sessionNum/revision/:revisionNum/project-document/:document`

// this is the aspect ratio which being used to ask AR from midjourney, not being used currently when generating with cadgen.
export const GENERATE_IMAGE_ASPECT_RATIO = env.REACT_APP_GENERATE_IMAGE_ASPECT_RATIO || '5:4'

// this is the aspect ratio being used for the magic eraser feature. not changing the generated aspect ratio which is being setup in th ebackend
export const MASK_IMAGE_ASPECT_RATIO = env.REACT_APP_MASK_IMAGE_ASPECT_RATIO || 640 / 480

// TODO: better create a hook such as useFeatures
export const FEATURE_PROFILE_PAGE = env.REACT_APP_FEATURE_PROFILE_PAGE === 'true' || false
export const FEATURE_SPEC_EXPORT = env.REACT_APP_FEATURE_SPEC_EXPORT === 'true' || true
export const FEATURE_PRODUCT_SUMMARY = env.REACT_APP_FEATURE_PRODUCT_SUMMARY === 'true' || true
export const FEATURE_VIEW_PROJECT_DOCS = env.REACT_APP_FEATURE_VIEW_PROJECT_DOCS === 'true' || true
export const FEATURE_TECH_SPEC = env.REACT_APP_FEATURE_TECH_SPEC === 'true' || true
export const FEATURE_BOM = env.REACT_APP_FEATURE_BOM === 'true' || false
export const FEATURE_GANTT = env.REACT_APP_FEATURE_GANTT === 'true' || false
export const FEATURE_COST = env.REACT_APP_FEATURE_COST === 'true' || false
export const FEATURE_SESSIONS_PAGE = env.REACT_APP_FEATURE_SESSIONS_PAGE === 'true' || true


// this message appearing once after generate is completed
export const generateCompletedMessage =
  "Here you go! Check out the new design concepts and tech spec and let me know if you'd like to make any changes. You can type here and/or add design inputs."

// this is the initial message showing once start the app
export const initialMessage = 'Hi, I’m Leo, your engineering design copilot. 👋  \n\n**What would you like to design?**'

// this is the message showing once after edit the messages in chat
export const editedMessage =
  "Ok, I've updated the description. Click the Generate button to generate new design concepts and tech spec."

export const userEditPrefix = 'Use the following edited product desciption'

export const finishGenerated =
  "Here you go! Check out the new design concepts and tech spec and let me know if you'd like to make any changes. You can type here and/or add design inputs."

export const VARIATIONS_MESSAGE =
  'In order to create variants, you can adjust the slider, and then click on the generate button'

export const UPLOAD_ERROR_MESSAGE = 'Invalid file format. Please select a png or jpg image.'

export const UPLOAD_ERROR_FILE_TOO_BIG = 'File is too large. Please select a smaller image.'

export const MAX_UPLOAD_IMAGE_SIZE_IN_BYTES = 3 * 1024 * 1024 // 3mb

export const mockDataObject : UpscaleData = {
  'upscale': [
    {
      'id': {
        'sessionNum': 40,
        'revisionNum': 1,
        'imageNum': 1,
      },
      'uri':
        'https://cdn.discordapp.com/attachments/1196441241060135072/1197095553905987685/midget1000_3D_CAD_model_of_A_big_blue_cup_with_a_smooth_and_glo_a82c407e-59fe-46b2-a83a-7e2fb0e76d35.png?ex=65ba051d&is=65a7901d&hm=d3bb98df34837234e1ed517a26f45cfdfb2ef3e2782621c05499a48262aef970&',
    },
    {
      'id': {
        'sessionNum': 40,
        'revisionNum': 1,
        'imageNum': 2,
      },
      'uri':
        'https://cdn.discordapp.com/attachments/1196441241060135072/1197095562147795005/midget1000_3D_CAD_model_of_A_big_blue_cup_with_a_smooth_and_glo_86fc69a3-3486-4aab-b7e6-f4d94d625ab2.png?ex=65ba051e&is=65a7901e&hm=db35e293997ee4e403d303d955445af0e32bc351fb36ce30a0b0471f06b92f2f&',
    },
    {
      'id': {
        'sessionNum': 40,
        'revisionNum': 1,
        'imageNum': 3,
      },
      'uri':
        'https://cdn.discordapp.com/attachments/1196441241060135072/1197095569533968514/midget1000_3D_CAD_model_of_A_big_blue_cup_with_a_smooth_and_glo_69b9461d-e1be-4414-8ae5-d27e6bafc260.png?ex=65ba0520&is=65a79020&hm=f956ddccf771d0cd1386b69800c869e834ff577c9fc597809feff8b23a096635&',
    },
  ],
}
