import * as analytics from "../lib/analytics.ts";
import * as errors from "./errors.ts";
import { getSessionToken } from "@descope/react-sdk";
import {
  handleSchemaValidationError,
  handleUnknownError,
  Method,
} from "./error-handlers.ts";
import { BASE_URL } from "@/lib/config.ts";
import { blockApiError } from "@/api/utils.ts";

const POPUP_ENDPOINT = `${BASE_URL}/api/v1/popup`;

export type popupType = "chatSeenAt" | 'ideationSeenAt' | 'desktopAppSeenAt' | 'ideationTabButtonSeenAt'

export function usePopUp() {
  const sessionToken = getSessionToken();

  async function getPopUpData(): Promise<any> {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
    };

    try {
      const response = await fetch(POPUP_ENDPOINT, options);
      return handleResponse(response, "GET");
    } catch (error: any) {

      blockApiError({ error, elseFunction: () => {
          analytics.track("KYC Error", { error });
        }
      });

      throw new errors.UnknownApiError(`Error with fetching data from ${POPUP_ENDPOINT}`);
    }
  }

  async function putPopUp(popupType: popupType) {
    const options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify({ popupType }),
    };

    const response = await fetch(POPUP_ENDPOINT, options);
    return handleResponse(response, "PUT");
  }

  async function handleResponse(response: Response, method: Method) {
    if (!response.ok) {
      const error = await response.json();
      const args: any = {
        headers: response.headers,
        url: response.url,
        method,
      };

      if (error.isSchemaValidationError) {
        handleSchemaValidationError(error, args);
      }

      handleUnknownError(error, args);
    } else if (method !== 'PUT') {
      return response.json();
    }
  }

  return { putPopUp, getPopUpData };
}
