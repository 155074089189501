import { useLocation, useNavigate, To } from 'react-router-dom'

export function useContextualNavigate() {
  const routerNavigate = useNavigate();
  const location = useLocation();

  function navigate(route: To, options?: { replace?: boolean }) {
    routerNavigate(`${route}${location.search || ''}`, options);
  }

  return navigate;
}
